import React from 'react';
import { standardAlignedBox } from './utils';

const GeoCoordinates = ({ title }) => {
  return standardAlignedBox(title, (
    <img
      src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Blank_US_Map_%28states_only%29.svg/800px-Blank_US_Map_%28states_only%29.svg.png"
      alt="Map of the United States"
      style={{ maxHeight: '80%', maxWidth: '100%', objectFit: 'contain' }}
    />
  ));
};

export default GeoCoordinates;
