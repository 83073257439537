import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

// mui
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import { useRecoilValue } from 'recoil';
import PageTitle from '../../../components/PageTitle';
import { currentUser } from '../../../store';

const useStyles = makeStyles({
  subheading: {
    marginTop: 10,
    minHeight: 40,
  },
  card: {
    backgroundColor: "#f1f1f1 !Important",
  },
  nav_link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
});

const Page = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ReportCard = styled.div`
  width: 300px;
  max-width: 300px;
  min-width: 300px;
  margin-right: 20px;
  margin-bottom: 20px;
`;

const Heading = styled.h3`
  font-size: 18px;
  display: flex;
`;

const HeaderText = styled.div`
  padding-left: 5px;
`;

const NiceCard = ({ link, title, description, isExternalURL }) => {
  const classes = useStyles();

  const CardContentComponent = (
    <Card className={classes.card}>
      <CardContent>
        <Heading>
          <InsertChartIcon />
          <HeaderText>{title}</HeaderText>
        </Heading>
        <Typography variant="body2" component="p">
          <Typography
            className={classes.subheading}
            variant="body2"
            component="p"
          >
            {description}
          </Typography>
        </Typography>
      </CardContent>
      <CardActions>
        <Button sx={{ color: "#212529" }} size="small">
          View report
        </Button>
      </CardActions>
    </Card>
  );

  return (
    <ReportCard>
      {isExternalURL ? (
        <a className={classes.nav_link} href={link} target="_blank" rel="noreferrer">
          {CardContentComponent}
        </a>
      ) : (
        <NavLink className={classes.nav_link} to={link}>
          {CardContentComponent}
        </NavLink>
      )}
    </ReportCard>
  );
};

const Reports = () => {
  window.eventBus.dispatch("sidebarCollapse", false);
  const user = useRecoilValue(currentUser);

  const reports = user
    ?.userPermissions
    ?.filter(m => m.module_type === 'report')
    ?.map(m => {
      switch(m.link) {
        case 'looker': {
          const lookerReport = user
            ?.orgSettings
            ?.find(s => m.id === s.scoped_entity_id && s.setting_key === 'looker_url');

          if (!lookerReport)
            window.location.href = "/";

          return {
            ...m,
            link: `/account/looker/${lookerReport.setting_value}`,
          }
        }
        case 'url': {
          let externalURL = user
            ?.orgSettings
            ?.find(s => m.id === s.scoped_entity_id && s.setting_key === 'url');

          if (!externalURL)
            externalURL = { setting_value: '/' };

          const value = externalURL.setting_value;
          console.log('!!', { value })
          const link = (value === 'flex') ? `flex/${m.id}` : value;
          return {
            ...m,
            link,
          }
        }
        case 'flex': {
          return {
            ...m,
            link: `/account/reports/flex/${m.id}`,
          }
        }
        default:
          return {
            ...m,
            link: `/account/reports/${m.link}`,
          }
      }
    });

  return (
    <Page>
      <PageTitle>Reports</PageTitle>
      <Container>
        { reports?.map(report => {
          return <NiceCard
            title={report.name}
            description={report.description}
            link={report.link}
            isExternalURL={report.link.includes('http')}
          />
        })}
      </Container>
    </Page>
  );
};

export default Reports;
