import React from 'react';
import Box from '@mui/material/Box';

const SingleValue = ({ title, value }) => (
  <div
    style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        padding: '0',
        margin: '0',
      }}
  >
    <Box sx={{ textAlign: 'center', padding: '8px 0', background: '#ffffff', borderRadius: '8px' }}>
      <h2 style={{ margin: '0', fontSize: '1.5rem', color: '#333' }}>{value}</h2>
      <p style={{ margin: '4px 0 0', fontSize: '0.575rem', color: '#666' }}>{title}</p>
    </Box>
  </div>
);

export default SingleValue;
