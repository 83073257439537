import React from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { Tooltip } from '@mui/material';
import { generateColors } from './colors';
import { denormalizeFieldName, standardAlignedBox } from './utils';

const GridView = ({ title, data, fields }) => {
  const barChartInsideField = 'Cost_On_Hand';
  const tooltipDisabledFields = [];

  // create columns based on the fields
  const columns = fields.map((field) => {
    const initialFieldValues = {
      field,
      headerName: denormalizeFieldName(field),
      sortable: true,
    };
    if (field === barChartInsideField) {
      return {
        ...initialFieldValues,
        renderCell: (params) => {
          const value = params.value !== 'N/A' ? params.value || 0 : 0;
          const maxValue = Math.max(...data.map((row) => row[barChartInsideField] || 0));
          const percentage = (value / maxValue) * 100;
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                width: '100%',
              }}
            >
              <div
                style={{
                  height: '10px',
                  backgroundColor: generateColors(100)[Math.floor(100 - percentage)],
                  width: `${percentage}%`,
                  borderRadius: '4px',
                }}
              />
              <span style={{ fontSize: '12px', color: '#333' }}>
                {value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
              </span>
            </div>
          );
        },
        sortComparator: (v1, v2) => (v1 || 0) - (v2 || 0), // handle 'N/A' as 0 in sorting
        minWidth: 150,
      };
    }

    const possibleRenderCell = !tooltipDisabledFields.includes(field)
      ? {
        renderCell: (params) => (
          <Tooltip title={params.value} placement="top" enterDelay={500}>
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {typeof params.value === 'number' ? params.value.toFixed(1) : params.value}
            </div>
          </Tooltip>
        ),
      } : {};

      let minWidth = 100;
      switch (field) {
        case 'Region':
        case 'Gm_Percent':
        case 'Wos':
          minWidth = 75;
          break;
        case 'Ave_Weekly_Unit_Sales':
        case 'On_Hand':
        case 'Product_Name':
          minWidth = 150;
          break;
      }

      return {
      ...initialFieldValues,
      ...possibleRenderCell,
      minWidth,
      width: minWidth,
    };
  });

  // map data rows to include only specified fields
  const rows = data.map((row, index) => ({
    id: index, // MUI DataGrid requires a unique 'id' for each row
    ...fields.reduce((acc, field) => {
      acc[field] = row[field] || 0; // treat 'N/A' as 0
      return acc;
    }, {}),
  }));

  return standardAlignedBox(title, (
    <DataGridPro
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          whiteSpace: 'normal',
          lineHeight: 'normal'
        },
        '& .MuiDataGrid-columnHeader': {
          height: 'unset !important',
          textAlign: 'left',
        },
        '& .MuiDataGrid-columnHeaders': {
          maxHeight: '168px !important',
          backgroundColor: 'white',
          zIndex: 2
        }
      }}
      rows={rows}
      columns={columns}
      density='compact'
      pageSize={rows.length}
      rowsPerPageOptions={[rows.length]}
      disableSelectionOnClick
      initialState={{
        sorting: {
          sortModel: [
            {
              field: barChartInsideField,
              sort: 'desc',
            },
          ],
        },
      }}
      components={{
        Toolbar: GridToolbar
      }}
    />
  ));
};

export default GridView;
