import React from 'react';
import Chart from 'react-apexcharts';
import { generateColors } from './colors';
import { standardAlignedBox } from './utils';

const PieChart = ({ title, data }) => {
  // calculate the total value for percentages
  const totalValue = data.reduce((sum, item) => sum + item.value, 0);

  const uniqueLabels = Array.from(new Set(data.reduce((acc, item) => (
    [...acc, item.name]), []).sort()));

  // format data with percentage labels
  const formattedData = uniqueLabels.map((label) => {
    const matchingItem = data.find((item) => item.name === label);
    const value = matchingItem ? matchingItem.value : 0;
    return {
      name: `${label} (${((value / totalValue) * 100).toFixed(1)}%)`,
      value,
    };
  });

  const options = {
    labels: formattedData.map((item) => item.name),
    colors: generateColors(formattedData.length),
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        colors: ['#eee'],
      },
    },
    legend: {
      position: 'bottom',
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -10,
          minAngleToShowLabel: 10,
        },
      },
    },
  };

  const series = formattedData.map((item) => item.value);

  return standardAlignedBox(title, (
    <Chart options={options} series={series} type="pie" height="100%" />
  ));
};

export default PieChart;
