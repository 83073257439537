import React, { useMemo, useState } from 'react';
import { Button, Dialog, DialogContent, Slide } from '@mui/material';
import Box from '@mui/material/Box';
import crossfilter from 'crossfilter2';
import { buildHierarchy, findChildItems, normalizeFieldName } from './utils';
import BarChart from './BarChart';
import BootstrapInput from '../BootstrapInput';
import GeoCoordinates from './GeoCoordinates';
import GridView from './GridView';
import MultipleDonuts from './MultipleDonuts';
import PieChart from './PieChart';
import ProductTypeSelector from '../ProductTypeSelecter/ProductTypeSelector';
import SingleValue from './SingleValue';
import Filter from '../../pages/account/orders/purchase-order/components/Filter';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const FlexArea = ({ data, yaml }) => {
  // initialize Crossfilter instance
  const cf = useMemo(() => crossfilter(data), [data]);

  // dynamically create dimensions based on the YAML configuration
  const dimensions = useMemo(() => {
    // collect all unique filter keys from the `yaml` elements
    const fieldKeys = new Set();
    yaml[0].elements.forEach((element) => {
      if (element.fields) {
        Object.values(element.fields).forEach((val) => {
          const field = normalizeFieldName(val.split('.').pop());
          fieldKeys.add(field);
        });
      }
    });

    // create a dimension for each filter key
    const dimensionMap = {};
    fieldKeys.forEach((key) => {
      dimensionMap[key] = cf.dimension((d) => d[key]);
    });

    return dimensionMap;
  }, [cf, yaml]);

  const [categories, setCategories] = useState([]);
  const [showCategoriesDialog, setShowCategoriesDialog] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  // track filters and their initial state
  const [filters, setFilters] = useState({});
  const initialFilters = useMemo(() => {
    const defaults = {};
    yaml[0].filters.forEach((filter) => {
      const field = normalizeFieldName(filter.field.split('.').pop());
      defaults[field] = null; // default each to the 'All' option
    });
    return defaults;
  }, [yaml]);

  // function to reset filters to their initial state
  const resetFilters = () => {
    setFilters(initialFilters);
    setSelectedCategories([]);
  };

  // apply filters dynamically
  useMemo(() => {
    Object.entries(filters).forEach(([key, value]) => {
      if (dimensions[key]) {
        dimensions[key].filter(value || null);
      }
    });
  }, [filters, dimensions]);

  const filterOptions = useMemo(() => {
    try {
      setCategories(buildHierarchy(data))
    } catch (e) {
      console.error(e);
    }
    const options = {};
    yaml[0].filters.forEach((filter) => {
      const field = normalizeFieldName(filter.field.split('.').pop());
      if (dimensions[field]) {
        options[field] = Array.from(
          new Set(dimensions[field].top(Infinity).map((d) => d[field]))
        ).sort();
      }
    });
    return options;
  }, [dimensions, data, yaml]);

  const renderFilter = (filter) => {
    const field = normalizeFieldName(filter.field.split('.').pop());
    const options = filterOptions[field] || [];
    let input = (
      <Filter
        value={filters[field] || ''}
        defaultLabel="All"
        options={[{
          value: -1,
          label: 'All'
        }, ...(options || []).map((loc) => ({
          value: loc,
          label: loc,
        }))]}
        onChange={(newVal) => {
          setFilters((prev) => ({ ...prev, [field]: newVal || null }))
        }}
      />
    );

    if (field === 'Category') {
      input = (
        <BootstrapInput
          value={selectedCategories.length > 0 ? 'Filtered Categories' : 'All Categories'}
          readOnly
          style={{
            width: '200px',
            display: 'flex',
            alignItems: 'start'
          }}
          onClick={() => setShowCategoriesDialog(true)}
        />
      );
    }
    if (field === 'Sub_Category') {
      return null;
    }

    return (
      <div key={field} style={{ margin: '8px' }}>
        <label htmlFor={`filter-${field}`} style={{ display: 'block', marginBottom: '4px', fontWeight: 'bold' }}>
          {filter.title}
        </label>
        {input}
      </div>
    );
  };

  const renderSingleValue = (element, field) => {
    const dimension = dimensions[field];
    if (!dimension) return `Field not found: ${field}`;

    const filteredData = dimension.top(Infinity);
    const uniqueValues = new Set(filteredData.map((row) => row[field]));

    return <SingleValue title={element.title} value={uniqueValues.size.toFixed(0)} />;
  };

  const renderPieChart = (element, field) => {
    const dimension = dimensions[field];
    if (!dimension) return `Field not found: ${field}`;

    const filteredData = dimension.top(Infinity);

    const aggregatedData = filteredData.reduce((acc, row) => {
      const key = row[field] || 'Unknown';
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {});

    const formattedData = Object.entries(aggregatedData).map(([key, value]) => ({
      name: key,
      value,
    }));

    return <PieChart title={element.title} data={formattedData} />;
  };

  const renderBarChart = (element) => {
    const xField = normalizeFieldName(element.fields[0]); // x-axis field
    const yField = normalizeFieldName(element.fields[1]); // y-axis field

    // use filtered data for aggregation
    const dimension = dimensions[xField]; // use the dimension corresponding to xField
    const filteredData = dimension ? dimension.top(Infinity) : []; // get the filtered dataset

    // group and sum the data by xField
    const aggregatedData = filteredData.reduce((acc, row) => {
      const key = row[xField] || 'Unknown';
      acc[key] = (acc[key] || 0) + (row[yField] || 0);
      return acc;
    }, {});

    // sort the aggregated data by the summed yField values
    const sortedData = Object.entries(aggregatedData)
      .sort(([, valueA], [, valueB]) => valueB - valueA) // sort by summed values (desc)
      .map(([key, value]) => ({ [xField]: key, [yField]: value })); // convert back to an array

    return <BarChart title={element.title} data={sortedData} xField={xField} yField={yField} />;
  };

  const renderGridView = (element) => {
    const fields = element.fields.map((field) => normalizeFieldName(field));

    // fetch the filtered dataset
    const dimension = dimensions[fields[0]]; // use the first field for filtering
    const filteredData = dimension ? dimension.top(Infinity) : [];

    return <GridView title={element.title} data={filteredData} fields={fields} />;
  };

  const renderDonutMultiples = (element) => {
    const groupField = normalizeFieldName(element.fields[1]);
    const breakdownField = normalizeFieldName(element.fields[2]);

    const dimension = dimensions[groupField];
    const filteredData = dimension ? dimension.top(Infinity) : [];

    return <MultipleDonuts title={element.title} data={filteredData} groupField={groupField} breakdownField={breakdownField} />;
  };

  const renderElement = (element) => {
    const field = normalizeFieldName(element.fields[0]);

    switch (element.type) {
      case 'single_value':
        return renderSingleValue(element, field);

      case 'looker_pie':
        return renderPieChart(element, field);

      case 'looker_bar':
        return renderBarChart(element);

      case 'looker_grid':
        return renderGridView(element);

      case 'looker_geo_coordinates':
        return <GeoCoordinates title={element.title} />;

      case 'looker_donut_multiples':
        return renderDonutMultiples(element);

      default:
        return <div>Unsupported type: {element.type}</div>;
    }
  };

  // calculate the grid's maximum dimensions
  const { maxHeight, maxWidth } = yaml[0].elements.reduce(
    (acc, element) => {
      const gridHeight = element.row + (element.height || 0);
      const gridWidth = element.col + (element.width || 0);
      return {
        maxHeight: Math.max(acc.maxHeight, gridHeight),
        maxWidth: Math.max(acc.maxWidth, gridWidth),
      };
    },
    { maxHeight: 0, maxWidth: 0 }
  );

  return (
    <div>
      <Box>
        <Dialog
          open={showCategoriesDialog}
          TransitionComponent={Transition}
          onClose={() => setShowCategoriesDialog(false)}
          PaperProps={{
            sx: {
              width: '480px'
            }
          }}
        >
          <DialogContent sx={{ padding: 0 }}>
            <ProductTypeSelector
              defaultSelected={selectedCategories}
              categories={categories}
              onClose={() => setShowCategoriesDialog(false)}
              onApply={(ids) => {
                setSelectedCategories(ids);
                const categoryFilter = ids.length
                  ? (d) => {
                    const items = findChildItems(categories[0], d);
                    const match = items.some(item =>
                      ids.includes(item.id) || item.children.some(
                        child => ids.includes(child.id)
                      ));
                    return match;
                  }
                  : null;
                const subCategoryFilter = ids.length
                  ? (d) => {
                    const items = findChildItems(categories[0], d);
                    const match = items.some(item =>
                      item && ids.includes(`${item.parent_id}-${item.name}`)
                    );
                    return match;

                  }
                  : null;
                setFilters((prev) => ({
                  ...prev,
                  'Category': categoryFilter,
                  'Sub_Category': subCategoryFilter,
                }));

              }}
            />
          </DialogContent>

        </Dialog>
      </Box>
      {/* render filters */}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginBottom: '16px',
          gap: '8px',
        }}
      >
        {yaml[0].filters.map((filter) => renderFilter(filter))}
        {/* reset button */}
        <div
          key='reset-filters'
          style={{
            margin: '8px',
          }}
        >
          <Button
            onClick={resetFilters}
            style={{
              backgroundColor: '#00A9A7',
              border: '0px',
              color: 'white',
              height: '36.5px',
              marginTop: '23px',
              textTransform: 'uppercase',
            }}
          >
            Reset
          </Button>
        </div>
      </div>

      {/* render charts and other components */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(${maxWidth},1fr)`,
          gridTemplateRows: `repeat(${maxHeight}, 1fr)`,
          gap: '6px',
          padding: '0px',
          borderRadius: '8px',
          paddingBottom: '6px',
        }}
      >
        {yaml[0].elements.map((element) => (
          <Box
            key={element.title}
            sx={{
              gridColumn: `${element.col + 1} / span ${element.width}`,
              gridRow: `${element.row + 1} / span ${element.height}`,
              background: '#ffffff',
              border: '1px solid #ddd',
              borderRadius: '8px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              padding: '8px',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '1rem',
              color: '#333',
            }}
          >
            <div style={{
              color: '#666',
              fontSize: '0.875rem',
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignContent: 'top',
            }}
            >
              {renderElement(element)}
            </div>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default FlexArea;
