import React from 'react';
import Chart from 'react-apexcharts';
import { generateColors } from './colors';
import { standardAlignedBox } from './utils';

const MultipleDonuts = ({ title, data, groupField, breakdownField }) => {
  const uniqueLabels = Array.from(
    new Set(data.map((row) => row[breakdownField] || 'Unknown').sort())
  );

  const colorMapping = generateColors(uniqueLabels.length)

  const groupedData = data.reduce((acc, row) => {
    const category = row[groupField] || 'Unknown';
    const breakdown = row[breakdownField] || 'Unknown';

    if (!acc[category]) acc[category] = {};
    if (!acc[category][breakdown]) acc[category][breakdown] = 0;

    acc[category][breakdown] += 1;
    return acc;
  }, {});

  const normalizedData = Object.entries(groupedData).map(([category, counts]) => {
    const series = uniqueLabels.map((label) => counts[label] || 0);
    return { category, series };
  });

  const maxColumns = 3;

  return standardAlignedBox(title, (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${maxColumns}, 1fr)`,
          gap: '2px',
          justifyContent: 'center',
          alignItems: 'center',
          maxHeight: '300px',
        }}
      >
        {normalizedData.map(({ category, series }, index) => {
          const options = {
            chart: {
              type: 'donut',
            },
            labels: uniqueLabels,
            colors: colorMapping,
            legend: { show: false },
            plotOptions: {
              pie: {
                donut: {
                  size: '60%', // adjusted donut size down
                },
              },
            },
            dataLabels: { enabled: false },
          };

          return (
            <div
              key={index}
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0',
                margin: '0',
                boxSizing: 'border-box',
                width: '100%',
                height: '100%',
                position: 'relative',
              }}
            >
              <Chart options={options} series={series} type="donut" width="120" />
              <span style={{
                color: colorMapping[0],
                fontSize: '10px',
                fontWeight: 'bold',
                position: 'absolute',
                top: '30px',
              }}
              >{category}
              </span>
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '15px',
          justifyContent: 'center',
          marginTop: '15px',
          textAlign: 'center',
        }}
      >
        {uniqueLabels.map((label, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <div
              style={{
                width: '12px',
                height: '12px',
                backgroundColor: colorMapping[index],
                borderRadius: '50%',
              }}
            />
            <span style={{ fontSize: '12px' }}>{label}</span>
          </div>
        ))}
      </div>
    </>
  ));
};

export default MultipleDonuts;
