import React from 'react';
import Chart from 'react-apexcharts';
import { denormalizeFieldName, standardAlignedBox } from './utils';
import { generateColors } from './colors';

const BarChart = ({ title, data, xField, yField }) => {
  // group data by xField and aggregate yField values
  const groupedData = data.reduce((acc, row) => {
    const key = row[xField] || 'Unknown';
    acc[key] = (acc[key] || 0) + (row[yField] || 0);
    return acc;
  }, {});

  // extract categories and series for ApexCharts
  const categories = Object.keys(groupedData);
  const series = [
    {
      name: yField,
      data: Object.values(groupedData),
    },
  ];

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const actualCurrencyFormatter = (val) => {
    if (val >= 1_000_000) {
      return `$${(val / 1_000_000).toFixed(0)}M`; // millions
    } if (val >= 1_000) {
      return `$${(val / 1_000).toFixed(0)}K`; // thousands
    }
    return currencyFormatter.format(val); // anything smaller
  };

  const options = {
    chart: {
      type: 'bar',
    },
    legend: {
      show: false,
    },
    colors: generateColors(categories.length),
    xaxis: {
      categories,
      labels: {
        style: {
          fontSize: '10px',
        },
        formatter: actualCurrencyFormatter,
      },
      title: {
        text: `${denormalizeFieldName(yField)}`, // x-axis label
        style: {
          fontSize: '10px',
          fontWeight: 'normal',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '10px',
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '50%',
        distributed: true,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: actualCurrencyFormatter,
      },
    },
  };

  return standardAlignedBox(title, (
    <Chart options={options} series={series} type="bar" />
  ));
};

export default BarChart;
