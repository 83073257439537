import React from 'react';

export const normalizeFieldName = (field) => {
  switch (field) {
    case 'Products':
    case 'products':
      return 'Product_Name';
    case 'Locations':
    case 'locations':
      return 'Location';
  }
  return field
    .split('.')
    .pop()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('_');
};

export const denormalizeFieldName = (field) => {
  switch (field) {
    case 'On_Hand':
      return 'Units On Hand';
    case 'Gm_Percent':
      return 'GM %';
    case 'Wos':
      return 'WOS';
  }
  return field.replace(/[_]/g, ' ');
}

export const standardAlignedBox = (title, content) => (
  <div
    style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
  >
    <h3>{title}</h3>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      {content}
    </div>
  </div>  
);

export const findChildItems = (category, target) => {
  const { children, name } = category;
  if (name === target) return [category];
  if (!children || !children.length) return;
  return children.reduce((acc, child) => {
    const result = findChildItems(child, target);
    if (result) return [...acc, ...result];
    return acc;
  }, []);
};

export const buildHierarchy = (data) => {
  const categories = new Map();
  const PARENT_ID = 1;

  data.forEach((item) => {
    const { Category: category, Sub_Category: subCategory } = item;

    if (!categories.has(category)) {
      categories.set(category, {
        id: category,
        name: category,
        parent_id: PARENT_ID,
        children: [],
      });
    }

    if (subCategory) {
      const categoryNode = categories.get(category);
      if (!categoryNode.children.some((child) => child.name === subCategory))
        categoryNode.children.push({
          id: `${category}-${subCategory}`,
          name: subCategory,
          parent_id: category,
          children: [],
        });
    }
  });

  return [{
    id: PARENT_ID,
    name: 'Products',
    parent_id: null,
    children: Array.from(categories.values()).map((category) => ({
      ...category,
      children: category.children.sort((a, b) => a.name.localeCompare(b.name)),
    })).sort((a, b) => a.name.localeCompare(b.name)),
  }];
};
