import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import PageTitle from '../../../../components/PageTitle';
import { ACCESS_TOKEN_KEY, currentOrgId } from '../../../../store';
import Loader from '../../../../components/Loader';
import FlexArea from '../../../../components/Flex/FlexArea';

const FlexReport = () => {
  const { moduleId } = useParams();

  const orgID = useRecoilValue(currentOrgId);
  const [isLoading, setLoading] = useState(true);
  const [, setWindowHeight] = useState('560px');
  const [flexData, updateFlexData] = useState([]);
  const [yamlData, updateYamlData] = useState([]);

  const loadFlexData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/v1/api/bigquery/${orgID}/${moduleId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
        },
      });
      const { data, yaml } = res.data;
      updateFlexData(data);
      updateYamlData(yaml);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!orgID) return;
    if (window.innerHeight > 750) {
      setWindowHeight('620px')
    }
    loadFlexData().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgID]);

  if (isLoading) return <Loader />;

  return (
    <Box sx={{
      padding: '25px',
      display: 'flex',
      flexDirection: 'column',
      rowGap: '6px',
      height: '100%'
    }}
    >
      <PageTitle>
        Flex reporting
      </PageTitle>
      <FlexArea
        id="flex_report"
        data={flexData}
        yaml={yamlData}
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    </Box>
  )
}

export default FlexReport;
