export const GROWTHSAYER_BLUE = '#19d1dd';

export const GROWTHSAYER_PALETTE_START = '#095e65';
export const GROWTHSAYER_PALETTE_MID_A = '#2e787f';
export const GROWTHSAYER_PALETTE_MID_B = '#4da1aa';
export const GROWTHSAYER_PALETTE_END = '#8ecfd4';

export const variousBlueColors = [
  '#465881', // mid-blue
  '#5886a5', // lighter blue
  '#74a3d2', // sky blue
  '#a1c8f1', // pale blue
  '#d0e7ff', // very light blue
  '#003f5c', // deep blue
  '#2f4b7c', // mid-dark blue
];

// helper functions for converting colors
const parseForHsl = (slicedHex) => parseInt(slicedHex, 16) / 255;
const parseForHex = (hsl) => Math.round(hsl * 255).toString(16).padStart(2, '0');

// helper to convert hex to HSL
const hexToHsl = (hex) => {
  const r = parseForHsl(hex.slice(1, 3))
  const g = parseForHsl(hex.slice(3, 5))
  const b = parseForHsl(hex.slice(5, 7))

  const max = Math.max(r, g, b), min = Math.min(r, g, b);
  const l = (max + min) / 2;
  let h, s;

  if (max === min) {  // achromatic
    h = 0;
    s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }
  return [h * 360, s * 100, l * 100];
};

// helper to convert HSL to hex
const hslToHex = (h, s, l) => {
  const sPercent = s / 100;
  const lPercent = l / 100;

  const c = (1 - Math.abs(2 * lPercent - 1)) * sPercent;
  const x = c * (1 - Math.abs((h / 60) % 2 - 1));
  const m = lPercent - c / 2;

  let r = 0, g = 0, b = 0;
  if (h >= 0 && h < 60) {
    r = c; g = x; b = 0;
  } else if (h >= 60 && h < 120) {
    r = x; g = c; b = 0;
  } else if (h >= 120 && h < 180) {
    r = 0; g = c; b = x;
  } else if (h >= 180 && h < 240) {
    r = 0; g = x; b = c;
  } else if (h >= 240 && h < 300) {
    r = x; g = 0; b = c;
  } else if (h >= 300 && h < 360) {
    r = c; g = 0; b = x;
  }

  r = parseForHex(r + m);
  g = parseForHex(g + m);
  b = parseForHex(b + m);

  return `#${r}${g}${b}`;
};

/**
 * Generate an array of colors (in hex format) between two given hex colors
 * 
 * @param {number} count - number of colors to generate (default = 6)
 * @param {string} startHex - starting color in hex format
 * @param {string} endHex - ending color in hex format
 * @returns {string[]} - array of colors in hex format
 */
export const generateColors = (
  count = 6,
  startHex = GROWTHSAYER_PALETTE_START,
  endHex = GROWTHSAYER_PALETTE_END,
) => {
  if (count <= 4) {
    const predefinedPalette = [
      GROWTHSAYER_PALETTE_START,
      GROWTHSAYER_PALETTE_MID_A,
      GROWTHSAYER_PALETTE_MID_B,
      GROWTHSAYER_PALETTE_END,
    ];
    return predefinedPalette.slice(0, count);
  }

  const startHsl = hexToHsl(startHex);
  const endHsl = hexToHsl(endHex);
  const colors = [];
  for (let i = 0; i < count; i++) {
    const t = i / (count - 1); // interpolation factor
    const h = startHsl[0] + t * (endHsl[0] - startHsl[0]);
    const s = startHsl[1] + t * (endHsl[1] - startHsl[1]);
    const l = startHsl[2] + t * (endHsl[2] - startHsl[2]);
    colors.push(hslToHex(h, s, l));
  }
  return colors;
}
